.circleinfo {
    transition: all 0.5s ease;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-color: #002BFF;
    margin-left: 10px;
    margin-top: 1000px;
    position: absolute;
    box-shadow: 0px 15px 50px lightblue;
    border: solid lightblue 3px;
    z-index: 2;
}
.circleinfo img {
    width: 100%;
    border-radius: 50%;

}
.circleinfo.show {
    margin-left: 10px;
    margin-top: -10px;
}

/* glow */
.circleinfo:hover::before {
  content: "";
  background: linear-gradient(
    45deg,
    #00FFD5, #002BFF,lightblue
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  width: calc(100% + 4px);
  height:  calc(100% + 4px);
  filter: blur(8px);
  animation: glowing 20s linear infinite;
  transition: opacity .3s ease-in-out;
  border-radius: 50%;
  opacity: 1;
}
@keyframes glowing {
    0% {background-position: 0 0;}
    50% {background-position: 400% 0;}
    100% {background-position: 0 0;}
  }
  .circleinfo:hover {
    opacity: 1;
    transform: scale(1.2) rotate(360deg);
  }

.infobox
{
    margin-top: 20px;
    height: 300px;
    align-items: center;
    width: 70%;
    border-radius: 20px;
    display: flex;
    padding: 100px;
    background-color: #CCCCCC ;
}
.aboutinfo
{
    transition: all 0.5s ease;
    top: 1px;
    margin-left: 550px;
}
.aboutinfo.show
{
    top: 1px;
    margin-left: 350px;
}
.aboutme
{
    justify-content: space-between;
    display: flex;
    font-size: large;
    font-weight: bold;
    text-align: left;
    margin-left: -50px;
    position: absolute;
    top: 100px;
    width: 300px;
    overflow: hidden;
}
.dash
{
    position: absolute;
    top: 28px;
    left: 100px;
    width: 50%;
    border-top: solid #708090 2px;
}
.infoboxcontainer {

    display: flex;
    justify-content: center;
    height: 100vh;
}
.skills{
    justify-content: space-between;
    display: flex;
    font-size: large;
    font-weight: bold;
    text-align: left;
    margin-left: 9vw;
    position: absolute;
    top: 600px;
    width: 300px;
    overflow: hidden;
}
.ToolsBtnContainer
{
    position: absolute;
    text-align: left;
    top: 660px;
    margin-left: 9vw;
   
}
.ToolsBtn
{   
    margin: 3px;
    background-color: white;
    border: none; /* No border */
    padding: 8px 8px; /* Padding */
    text-align: center; /* Center text */
    text-decoration: none; /* No underline */
    display: inline-block; /* Inline-block display */
    font-size: 12px; /* Font size */
    font-weight: bold; /* Bold text */
    border-radius: 8px; /* Rounded corners */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Transition for hover effects */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
    cursor: pointer; /* Pointer cursor on hover */
}
.ToolsBtn:hover {
    background-color: #87CEFA; /* Slightly darker light blue on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}
.ToolsBtn.show {
    background-color: #87CEFA; /* Slightly darker light blue on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}

.skilldash
{
    position: absolute;
    top: 28px;
    left: 60px;
    width: 40%;
    border-top: solid #708090 2px;
}
.stocks
{
    width: 100%;
    position: absolute;
    top: 700px;
    display: grid;
    font-size: 14px;
    justify-content: center;
    grid-template-columns: auto auto auto auto;
}
.techstocks
{
    opacity: 0;
    transition: all 0.3s ease;
    width: 150px;
    height: 30px;
    margin: 1vw 5vw;
    padding: 10px;
    background-color: #f9f9f9;
    font-size: 16px;
    line-height: 1.5;
    border: solid #708090;
    border-radius: 5px;
    animation: fadeIn 1s forwards;
}
.techstocks.show{
    animation: fadeIns 1s forwards; 
}


.techstocks:nth-child(1) {
    animation-delay: 0.1s;
  }
  
  .techstocks:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .techstocks:nth-child(3) {
    animation-delay: 0.3s;
  }
  
  .techstocks:nth-child(4) {
    animation-delay: 0.4s;
  }
  
  .techstocks:nth-child(5) {
    animation-delay: 0.5s;
  }
  
  .techstocks:nth-child(6) {
    animation-delay: 0.6s;
  }
  
  .techstocks:nth-child(7) {
    animation-delay: 0.7s;
  }
  
  .techstocks:nth-child(8) {
    animation-delay: 0.8s;
  }
  
  .techstocks:nth-child(9) {
    animation-delay: 0.9s;
  }
  
  .techstocks:nth-child(10) {
    animation-delay: 1s;
  }
.techstocks:hover
{
    line-height: 1.0;
    background-color: #CCCCCC;
}
@keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIns {
    to {
      opacity: 1;
    }
}
.hover-text
{
    font-size: 12px;
    color: #708090;
}
.stocksicon
{
  transition: all 0.3s ease;
  position: relative;
  text-align:center;
  width: 25px;
  top: 2px;
  margin-right: 10px;
  float: left;

}
.stocksicon.show
{
    transform: scale(1.2);
}

@media screen and (max-width:1200px)
{
    .stocks
    {
        grid-template-columns:  auto auto auto;
    }
    .circleinfo {
        width: 200px;
        height: 200px;
    }
    .aboutinfo.show
    {
        top: 1px;
        margin-left: 250px;
        
    }
}


@media screen and (max-width:767px)
{
    .circleinfo {
        transition: all 0.5s ease;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #002BFF;
        margin-left: 10px;
        margin-top: 1000px;
        position: absolute;
        box-shadow: 0px 15px 50px lightblue;
        border: solid lightblue 3px;
        z-index: 2;
    }
    .aboutinfo
    {
        transition: all 0.5s ease;
        top: 1px;
        margin-left: 50px;
    }
    .aboutinfo.show
{
    top: 1px;
    margin-left: 110px;
    
}
.circleinfo.show {
    margin-left: -60px;
    margin-top: -10px;
}
}