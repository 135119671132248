.circle {
    transition: all 2s ease;
    width: 350px;
    height: 350px;
    border-radius: 50%;
    overflow: hidden;
    right: -500px;
    margin-top: 10vh;
    top: 10vh;
    position: absolute;
    box-shadow: 0px 15px 50px lightblue;
    border: solid lightblue 3px;
}

.circle img {
    width: 100%;
}

p {
    width: 500px;
}

i {
    color: lightblue;
}

.bio {
    display: inline-block;
    transition: all 2s ease;
    left: -500px;
    margin-top: 18vh;
    letter-spacing: .15em;
}

.circle.show {
    right: 20vw;
}
.bio.show{
    margin-left: 20vw;
}



/* Typing effect animation */
.typing {
    border-right: 2px solid black;
    overflow: hidden;
    white-space: nowrap;
    animation: typing 2s steps(20) infinite alternate, 
    cursor .2s step-end infinite alternate;
    width: max-content;
}

/* Typing effect keyframes */
@keyframes typing {
    from {
        width: 0%;
    }
    to
    {
        width: 40%;
    }

}

@keyframes cursor {
    50%{border-color: transparent;}
  
}

@media screen and (max-width:1200px)
{
   
    .circle
    {
        transition: all 2s ease;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        right: -500px;
        margin-top: 10vh;
        top: 10vh;
        position: absolute;
        box-shadow: 0px 15px 50px lightblue;
        border: solid lightblue 3px;
        
    }
}