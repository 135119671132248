.proContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
     margin-top: 35px;
  }
  
  .projectCard {
    background-color: #CCCCCC;
    border-radius: 10px;
    color: black;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .projectImg {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    overflow: hidden;
    object-position: center;
    object-fit: cover;

  }
  
  .projectContent {
 
    padding: 10px 10px;
  }
  
  .projectContent h2 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .projectContent p {
    margin: 10px 0px;
    width: 100%;
    
  }
  
  .technologies {
    font-style: italic;
    color: #999;
  }
  .proTitle
  {
    justify-content: center;
    text-align: center;
    line-height: 0.5;

  }
  .proLook
  {
    width: 100%;
    text-align: center;
    justify-self: center;
    
  }
  